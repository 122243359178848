
















































































































































import Vue from "vue";
// import mypost from "../assets/paralax-back.jpg";

export default Vue.extend({
  data() {
    return {
      loading: false,
      selection: 1,
      // mypost: mypost
    };
  },
  methods: {
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
  },
});
